import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../actions/index'
// import ReCAPTCHA from 'react-google-recaptcha'
import HCaptcha from '@hcaptcha/react-hcaptcha';
import '../App2.css';
import toast from 'react-hot-toast'


class Login extends React.Component {
    constructor() {
        super()
        this.reCap = React.createRef();
        this.state = {
            email: '',
            password: '',
            token:null
        }
    }

    onExpire = () => {
        console.log("hCaptcha Token Expired");
        toast.error("hCaptcha Token Expired");
    };
    
    onError = (err) => {
        console.log(`hCaptcha Error: ${err}`);
        toast.error(`hCaptcha Error: ${err}`);
    };

    handleChange = (evt) => {
        evt.preventDefault()
        this.setState({
            [evt.target.name]: evt.target.value,
        })
    }

    handleChangeHcap = (val) => {
        console.log('val passed into changehcap', val)
        this.setState({
            token: val,
        })
    }

    handleSubmit = async (evt) => {
        evt.preventDefault()
        const { email, password } = this.state
        const token2 = await this.reCap.current.execute()
        // const token = await this.reCap.current.executeAsync()
        // this.reCap.current.reset()
        console.log('token passed to props', token2)
        this.props.login(email, password, this.state.token)
            .then(() => {
                this.props.history.push("./dashboard2")
            })
            .catch((err) => { console.error(err) })
    }

    render() {
        const { email, password } = this.state
        const { isLoading } = this.props
        return (
            <div>
                <h1>Log In</h1>
                <br/>
                {/* <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHAPUBLIC} size="invisible" ref={this.reCap} /> */}
                <form onSubmit={this.handleSubmit}>
                    {/* {errorMessage && <p className="error">{errorMessage}</p>} */}
                    <input type="text" name="email" placeholder="email" value={email} onChange={this.handleChange} autocomplete="username" /><br />
                    <input type="password" name="password" placeholder="Password" value={password} onChange={this.handleChange} autocomplete="current-password" /><br />
                    <HCaptcha
                        sitekey="3cbef3b6-2276-4ac8-8986-9be45e34f32c"
                        // onLoad={onLoad}
                        data-theme='dark'
                        size='compact'
                        onVerify={this.handleChangeHcap}
                        onError={this.onError}
                        onExpire={this.onExpire}
                        ref={this.reCap}
                    />
                    <br />
                    {isLoading
                        ? <p>Logging in...</p>
                        : <button className="abutton" type="submit">Log In</button>}
                </form>
                <br />
                <div>
                    <Link to='/resetpassword'><span className="abutton">Request Password Reset Email</span></Link>
                    <br/> <br />
                    <Link to='/resetpwcode'><span className="abutton">Enter Password Reset Code from Email</span></Link>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.isLoading,
    errorMessage: state.errorMessage
})

const mapDispatchToProps = {
    login,
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Login)
)