import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'
// import ReCAPTCHA from 'react-google-recaptcha'
import HCaptcha from '@hcaptcha/react-hcaptcha';
import toast from 'react-hot-toast'

const HomepageAvailability = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [chosenCustom, setChosenCustom] = useState('')
    const [isAvailable, setIsAvailable] = useState(false)
    const [isNotAvailable, setIsNotAvailable] = useState(false)
    const [lastCheckedCURL, setLastCheckedCURL] = useState('')
    const [prefixHost, setPrefixHost] = useState('https://link-in.bio/')

    const [token, setToken] = useState(null);
    const captchaRef = useRef(null);
    // const reRef = useRef(null)

    const handleChange = (event) => {
        event.preventDefault()
        setChosenCustom(event.target.value)
    }

    const setPrefix = (event) => {
        event.preventDefault()
        setPrefixHost(event.target.value)
    }

    // function onChange(value) {
    //     console.log("Captcha value:", value);
    // }

    const hasNoIllegalChars = (value) => {
        // const stringHasSpaces = value.indexOf(' ')
        const stringHasIllegalSlash1 = value.indexOf(`\\`)
        const stringHasIllegalSlash2 = value.indexOf(`/`)
        const stringHasIllegalQuote1 = value.indexOf(`'`)
        const stringHasIllegalQuote2 = value.indexOf(`"`)
        const stringHasIllegalSemicolon = value.indexOf(`;`)
        const stringHasIllegalColon = value.indexOf(`:`) 
        const stringHasIllegalCaret = value.indexOf(`^`)
        const stringHasIllegalStar = value.indexOf(`*`)
        const stringHasIllegalHTML = value.indexOf(`<`)
        const stringHasIllegalPercent = value.indexOf('%')
        if(
            stringHasIllegalSlash1 === -1 &&
            stringHasIllegalSlash2 === -1 &&
            stringHasIllegalQuote1 === -1 &&
            stringHasIllegalQuote2 === -1 &&
            stringHasIllegalSemicolon === -1 &&
            stringHasIllegalColon === -1 &&
            stringHasIllegalCaret === -1 &&
            stringHasIllegalHTML === -1 &&
            stringHasIllegalStar === -1 &&
            stringHasIllegalPercent === -1
            // stringHasSpaces === -1 && 
        ){
            return true
        } else {
            return false
        }
    }

    // const onLoad = () => {
    //     // this reaches out to the hCaptcha JS API and runs the
    //     // execute function on it. you can use other functions as
    //     // documented here:
    //     // https://docs.hcaptcha.com/configuration#jsapi
    //     captchaRef.current.execute();
    // };
    
    useEffect(() => {
    if (token)
        console.log(`hCaptcha Token: ${token}`);
    }, [token]);

    const submitCheckChosenCustom = async (event) => {
        event.preventDefault()
        //recaptcha code
        // const cap_token = await reRef.current.executeAsync()
        // reRef.current.reset()
        // const htoken = await captchaRef.current.execute();
        // console.log('token', token)
        if(token == null){
            toast.error('Please Complete hCaptcha')
            return false
        }
        // let htoken = await captchaRef.current.execute();
        // console.log('cap_token', htoken)
        // console.log('cap_token', cap_token, htoken)
        setIsAvailable(false)
        setIsNotAvailable(false)
        setIsLoading(true)
        const intermediate = chosenCustom.split(" ").join("").toLowerCase()
        if(hasNoIllegalChars(intermediate) === true){
            setChosenCustom('')
            const secondaryIntermediate = `${prefixHost}${intermediate}`
            setLastCheckedCURL(`${secondaryIntermediate.indexOf('xn--')!=-1?'еф.ею/'+secondaryIntermediate.slice(28):secondaryIntermediate.slice(8)}`)
            // console.log('secondaryIntermediate', secondaryIntermediate)
            return axios.post('https://link-in-bio.limited/l/checkCHomepage', {customURL:secondaryIntermediate, token:token})
            .then(res => {
                // console.log('res.data chosenCustom', res.data)
                if(res.data.length === 0){
                    setIsAvailable(true)
                    setIsLoading(false)
                    // alert('Available')
                    toast.success('Available')
                    // reRef.current.reset()
                } else {
                    setIsNotAvailable(true)
                    setIsLoading(false)
                    toast.error('Already Taken')
                    // reRef.current.reset()
                    // alert('Already Taken')
                }
            })
        } else {
            // alert(`There are illegal characters in your input, please remove them and try again`)
            toast.error(`There are illegal characters in your input, please remove them and try again`)
            setIsLoading(false)
            return false
        }
    }

    const onExpire = () => {
        console.log("hCaptcha Token Expired");
        toast.error("hCaptcha Token Expired");
    };
    
    const onError = (err) => {
        console.log(`hCaptcha Error: ${err}`);
        toast.error(`hCaptcha Error: ${err}`);
    };

    return (
        <div>
            <br />
            <h3>Choose the URL that Best fits your style:</h3>
            <br />
            <form onSubmit={submitCheckChosenCustom}>
            {/* <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHAPUBLIC} size="invisible" ref={reRef} /> */}
                <label>
                    Is The URL You Want Available? Check Here:<br />
                    <select style={{ "cursor": "pointer" }} onChange={setPrefix}>
                        <option value="https://link-in.bio/">link-in.bio/</option>
                        <option value="https://link-me.ee/">link-me.ee/</option>
                        <option value="https://xn--e1a4a.xn--e1a4c/">еф.ею/</option>
                        <option value="https://what-i.lv/">what-i.lv/</option>
                        <option value="https://pstd.at/">pstd.at/</option>
                        <option value="https://7zz.ch/">7zz.ch/</option>
                        <option value="https://this-links.to/">this-links.to/</option>
                        <option value="https://resumelink.me/">resumelink.me/</option>
                    </select>
                </label>
                <input style={{ "cursor": "pointer" }} onChange={handleChange} value={chosenCustom} name="CustomURL" type="text" required maxLength="127" placeholder="YourNameHere"  />
                <br />
                <HCaptcha
                    sitekey="3cbef3b6-2276-4ac8-8986-9be45e34f32c"
                    // onLoad={onLoad}
                    data-theme='dark'
                    size='compact'
                    onVerify={setToken}
                    onError={onError}
                    onExpire={onExpire}
                    ref={captchaRef}
                />
                <br />
                {isLoading? <p>Searching Database...</p>:<button style={{ "cursor": "pointer" }} type="submit">Check CustomURL Availability</button>}
            </form>
            <br />
            {isAvailable ? <div><p>✔️ {lastCheckedCURL} is Available!</p><br /></div> : null}
            {isNotAvailable ? <div><p>❌ {lastCheckedCURL} is already taken, sorry!</p><br /></div> : null}
        </div>
    )

}

export default HomepageAvailability